.scroller {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
.scroller_reverse {
  display: flex;
  animation: swipe_reverse var(--speed) linear infinite backwards;
}

@keyframes swipe_reverse {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(0%);
  }
}
.scrollingImg {
  max-width: 150px;
  height: 5rem;
  padding: 0 15px;
  object-fit: cover;
}

.scrollingImg:last-of-type {
  padding-left: 0;
}

.about {
  overflow: hidden;
  position: relative;
}

.aboutBG {
  /* background-image: url("./Images/gif.gif"); */
  opacity: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  -webkit-transition: -webkit-filter 1000ms linear;
}

.aboutBGactive {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  filter: blur(5px) opacity(75%);
  -webkit-transition: -webkit-filter 1000ms linear;
  z-index: -1;
}

.aboutContent {
  opacity: 0%;
  -webkit-transition: -webkit-filter 1000ms linear;
}
.aboutContentActive {
  opacity: 100%;
  -webkit-transition: -webkit-filter 1000ms linear;
}
